// src/context/UserContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from '@clerk/clerk-react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs'; // Ensure dayjs is installed: npm install dayjs

// Create the UserContext
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isLoaded, isSignedIn, userId, user: clerkUser } = useAuth();

  // Initialize user state based on the updated User schema
  const [user, setUser] = useState({
    _id: '',
    clerkUserId: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    subscriptions: [],
    enrolledLessons: [],
  });

  // New state for enrolled courses
  const [enrolledCourses, setEnrolledCourses] = useState([]);

  // New state for enrolled lessons
  const [enrolledLessons, setEnrolledLessons] = useState([]);

  // New state for upcoming lessons
  const [upcomingLessons, setUpcomingLessons] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createNewUser = useCallback(async () => {
    if (isLoaded && isSignedIn && userId) {
      console.log(clerkUser, "Creating new user");
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clerkUserId: userId,
            fullName: clerkUser?.fullName || '',
            email: clerkUser?.primaryEmailAddress?.emailAddress || '',
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setUser((prevUser) => ({
            ...prevUser,
            ...data, // Update user with data returned from the server
          }));
        } else {
          const errorData = await response.json();
          console.error('Failed to create new user:', errorData.error || response.statusText);
          setError(errorData.error || 'Failed to create new user.');
        }
      } catch (err) {
        console.error('Error creating new user:', err);
        setError('An unexpected error occurred while creating the user.');
      } finally {
        setLoading(false);
      }
    }
  }, [isLoaded, isSignedIn, userId, clerkUser]);

  // Function to fetch user data
  const fetchUserData = useCallback(async () => {
    if (isLoaded && isSignedIn && userId) {
      setLoading(true);
      setError(null);
      try {
        console.log(process.env.REACT_APP_INFINITY_BACK_URL);
        const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUser({
            _id: data._id,
            clerkUserId: data.clerkUserId || userId,
            fullName: data.fullName || '',
            email: data.email || '',
            phoneNumber: data.phoneNumber || '',
            subscriptions: data.subscriptions || [],
            enrolledLessons: data.enrolledLessons || [],
          });
        } else if (response.status === 404) {
          // If user not found, create a new user
          console.log("Creating new user");
          await createNewUser();
        } else {
          const errorData = await response.json();
          console.error('Failed to fetch user data:', errorData.error || response.statusText);
          setError(errorData.error || 'Failed to fetch user data.');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('An unexpected error occurred while fetching user data.');
      } finally {
        setLoading(false);
      }
    }
  }, [isLoaded, isSignedIn, userId, createNewUser]);

  // Function to create a new user

  // Function to check access
  const hasAccess = useCallback(
    async (resourceType, resourceId) => {
      if (!isLoaded || !isSignedIn || !userId) {
        console.warn('User is not authenticated.');
      }
      if(!userId){
        try {
          const response = await fetch(
            `${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/hasAccess/${resourceType}/${resourceId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            if(data.message == "whitelist")
              return true;
            return data.hasAccess;
          } else {
            const errorData = await response.json();
            console.error('Failed to check access:', errorData.message || response.statusText);
            setError(errorData.message || 'Failed to check access.');
            return false;
          }
        } catch (err) {
          console.error('Error checking access:', err);
          setError('An unexpected error occurred while checking access.');
          return false;
        }
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/${userId}/hasAccess/${resourceType}/${resourceId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          return data.hasAccess;
        } else {
          const errorData = await response.json();
          console.error('Failed to check access:', errorData.message || response.statusText);
          setError(errorData.message || 'Failed to check access.');
          return false;
        }
      } catch (err) {
        console.error('Error checking access:', err);
        setError('An unexpected error occurred while checking access.');
        return false;
      }
    },
    [isLoaded, isSignedIn, userId]
  );

  // Function to fetch enrolled courses (already implemented)
  const fetchEnrolledCourses = useCallback(async () => {
    if (!isLoaded || !isSignedIn || !userId) {
      console.warn('User is not authenticated.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/${userId}/enrolledCourses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setEnrolledCourses(data.enrolledCourses || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch enrolled courses:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch enrolled courses.');
      }
    } catch (err) {
      console.error('Error fetching enrolled courses:', err);
      setError('An unexpected error occurred while fetching enrolled courses.');
    } finally {
      setLoading(false);
    }
  }, [isLoaded, isSignedIn, userId]);
  // Function to fetch both enrolled and upcoming lessons
  const fetchEnrolledAndUpcomingLessons = useCallback(async () => {
    if (!isLoaded || !isSignedIn || !userId) {
      console.warn('User is not authenticated.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/${userId}/enrolledAndUpcomingLessons`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setEnrolledLessons(data.enrolledLessons || []);
        setUpcomingLessons(data.upcomingLessons || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch lessons:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch lessons.');
      }
    } catch (err) {
      console.error('Error fetching lessons:', err);
      setError('An unexpected error occurred while fetching lessons.');
    } finally {
      setLoading(false);
    }
  }, [isLoaded, isSignedIn, userId]);
  // Function to enroll in a lesson
  const enrollInLesson = useCallback(
    async (lessonId) => {
      if (!isLoaded || !isSignedIn || !userId) {
        console.warn('User is not authenticated.');
        setError('User is not authenticated.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_INFINITY_BACK_URL}/api/lessons/${lessonId}/${userId}/enroll`,
          {
            method: 'POST', // Changed to POST as enrolling is an action that modifies data
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Update enrolledLessons
          setEnrolledLessons((prevLessons) => [...prevLessons, data.lesson]);
          // Remove from upcomingLessons
          setUpcomingLessons((prevUpcoming) =>
            prevUpcoming.filter((lesson) => lesson._id !== lessonId)
          );
        } else {
          const errorData = await response.json();
          console.error('Failed to enroll in lesson:', errorData.error || response.statusText);
          setError(errorData.error || 'Failed to enroll in lesson.');
        }
      } catch (err) {
        console.error('Error enrolling in lesson:', err);
        setError('An unexpected error occurred while enrolling in lesson.');
      } finally {
        setLoading(false);
      }
    },
    [isLoaded, isSignedIn, userId]
  );
  // Function to cancel lesson enrollment
  const cancelLesson = useCallback(
    async (lessonId) => {
      if (!isLoaded || !isSignedIn || !userId) {
        console.warn('User is not authenticated.');
        setError('User is not authenticated.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_INFINITY_BACK_URL}/api/lessons/${lessonId}/${userId}/cancel`,
          {
            method: 'POST', // Changed to POST as cancelling modifies data
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Update enrolledLessons by removing the cancelled lesson
          setEnrolledLessons((prevLessons) =>
            prevLessons.filter((lesson) => lesson._id !== lessonId)
          );
          // Optionally, add the lesson back to upcomingLessons if applicable
          // setUpcomingLessons((prevUpcoming) => [...prevUpcoming, data.lesson]);
        } else {
          const errorData = await response.json();
          console.error('Failed to cancel lesson enrollment:', errorData.error || response.statusText);
          setError(errorData.error || 'Failed to cancel lesson enrollment.');
        }
      } catch (err) {
        console.error('Error cancelling lesson enrollment:', err);
        setError('An unexpected error occurred while cancelling lesson enrollment.');
      } finally {
        setLoading(false);
      }
    },
    [isLoaded, isSignedIn, userId]
  );
  // Function to update user data (already implemented)
  const updateUser = useCallback(
    async (updatedFields) => {
      if (!isLoaded || !isSignedIn || !userId) {
        console.warn('User is not authenticated.');
        setError('User is not authenticated.');
        return;
      }
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...updatedFields }),
        });

        if (response.ok) {
          const updatedUser = await response.json();
          setUser((prevUser) => ({
            ...prevUser,
            ...updatedUser,
          }));
        } else {
          const errorData = await response.json();
          console.error('Failed to update user data:', errorData.error || response.statusText);
          setError(errorData.error || 'Failed to update user data.');
        }
      } catch (err) {
        console.error('Error updating user data:', err);
        setError('An unexpected error occurred while updating user data.');
      } finally {
        setLoading(false);
      }
    },
    [isLoaded, isSignedIn, userId]
  );
  // Function to create a subscription (already implemented)
  const createSubscription = useCallback(
    async (plan) => {
      if (!plan) {
        console.warn('No plan selected.');
        setError('No plan selected.');
        return;
      }

      if (!isLoaded || !isSignedIn || !userId) {
        console.warn('User is not authenticated.');
        setError('User is not authenticated.');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        console.log(userId, plan._id, "Creating subscription");
        // Send subscription creation request to the server
        const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/subscriptions/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ planId: plan._id, clerkUserId: userId }),
        });

        if (response.ok) {
          const data = await response.json();
          setUser((prevUser) => ({
            ...prevUser,
            subscriptions: [...prevUser.subscriptions, data.subscription],
          }));
          // Clear selected plan
          setSelectedPlan(null);
          // Fetch enrolled courses and lessons again to update state
          await fetchEnrolledAndUpcomingLessons();
        } else {
          const errorData = await response.json();
          console.error('Failed to create subscription:', errorData.error || response.statusText);
          setError(errorData.error || 'Failed to create subscription.');
        }
      } catch (err) {
        console.error('Error creating subscription:', err);
        setError('An unexpected error occurred while creating the subscription.');
      } finally {
        setLoading(false);
      }
    },
    [isLoaded, isSignedIn, userId, fetchEnrolledAndUpcomingLessons]
  ); 
  // Function to fetch subscription history (already implemented)
  const fetchSubscriptionHistory = useCallback(async () => {
    if (!isLoaded || !isSignedIn || !userId) {
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/users/${userId}/subscriptions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser((prevUser) => ({
          ...prevUser,
          subscriptions: data.subscriptions || [],
        }));
        // Fetch enrolled courses and lessons again to keep data updated
        await fetchEnrolledAndUpcomingLessons();
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch subscription history:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch subscription history.');
      }
    } catch (err) {
      console.error('Error fetching subscription history:', err);
      setError('An unexpected error occurred while fetching subscription history.');
    } finally {
      setLoading(false);
    }
  }, [isLoaded, isSignedIn, userId, fetchEnrolledAndUpcomingLessons]);

  // Fetch user data and enrolled courses & lessons on component mount and when dependencies change
  useEffect(() => {
    fetchUserData();
    fetchEnrolledCourses();
    fetchEnrolledAndUpcomingLessons();
  }, [fetchUserData, fetchEnrolledCourses, fetchEnrolledAndUpcomingLessons]);

  const contextValue = {
    user,
    isSignedIn,
    loading,
    error,
    enrolledCourses, // Provide enrolled courses via context
    enrolledLessons, // Provide enrolled lessons via context
    upcomingLessons, // Provide upcoming lessons via context
    selectedPlan,
    enrollInLesson,
    cancelLesson,
    subscriptions: user.subscriptions,
    hasAccess,
    createSubscription, // Function to create a subscription after payment
    updateUser, // General function to update user data
    fetchUserData, // Function to fetch user data
    fetchSubscriptionHistory, // Function to fetch subscription history
    fetchEnrolledCourses, // Function to fetch enrolled courses
    fetchEnrolledAndUpcomingLessons, // Function to fetch enrolled and upcoming lessons
    createNewUser, // Function to create a new user
    setError, // Function to set error manually
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
