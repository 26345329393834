import React, { useContext, useState } from 'react';
import {
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import {
  SignedIn,
  SignedOut,
  useUser,
} from '@clerk/clerk-react';
import Sidebar from './Sidebar';
import MobileDrawer from './MobileDrawer';
import BottomNav from './BottomNav';
import ThemeToggle from './ThemeToggle'; // If needed elsewhere
import { ThemeContext } from '../../context/ThemeContext';
// Import necessary icons
import {
    Home as HomeIcon,
    MenuBook as MenuBookIcon,
    Assignment as AssignmentIcon,
    Info as InfoIcon,
    ContactMail as ContactMailIcon,
    Policy as PolicyIcon,
    Person as PersonIcon,
    Subscriptions as SubscriptionsIcon,
    School as SchoolIcon,
  } from '@mui/icons-material';
  
const Navbar = () => {
  const theme = useTheme();
  const { toggleTheme } = useContext(ThemeContext);
  const { isSignedIn } = useUser();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState(location.pathname);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sidebarItems = [
    { name: 'דף הבית', link: '/', icon: <HomeIcon /> },
    { name: 'כל הקורסים', link: '/all-courses', icon: <MenuBookIcon /> },
    { name: 'תוכניות לימוד', link: '/all-plans', icon: <AssignmentIcon /> },
    { name: 'אודות', link: '/about-us', icon: <InfoIcon /> },
    { name: 'צור קשר', link: '/contact', icon: <ContactMailIcon /> },
    { name: 'מדיניות פרטיות', link: '/privacy-policy', icon: <PolicyIcon /> },
  ];

  if (isSignedIn) {
    sidebarItems.push({
      name: 'חשבון אישי',
      icon: <PersonIcon />,
      submenu: [
        {
          name: 'המנויים שלי',
          link: '/my-subscription',
          icon: <SubscriptionsIcon />,
        },
        { name: 'הקורסים שלי', link: '/my-courses', icon: <MenuBookIcon /> },
        { name: 'השיעורים שלי', link: '/my-lessons', icon: <SchoolIcon /> },
        // Uncomment if needed
        // {
        //   name: 'חומרי לימוד שלי',
        //   link: '/my-study-materials',
        //   icon: <LibraryBooksIcon />,
        // },
      ],
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* Responsive Navigation */}
      {isMobile ? (
        <>
          <MobileDrawer
            open={mobileMenuOpen}
            onClose={handleMobileMenuToggle}
            sidebarItems={sidebarItems}
          />

          {/* Bottom Navigation */}
          <BottomNav
            value={value}
            onChange={handleChange}
            onMenuOpen={handleMobileMenuToggle}
            isSignedIn={isSignedIn}
          />
        </>
      ) : (
        /* Desktop Navigation */
        <Sidebar
          drawerWidth={260}
          sidebarItems={sidebarItems}
        />
      )}
      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ml: isMobile ? 0 : `260px`,
          mt: isMobile ? 0 : '0',
          p: isMobile ? 0 : 3,
          pb: isMobile ? '72px' : 3, // Padding bottom for mobile to avoid overlap with BottomNav
        }}
      >
        <Toolbar />
        {/* Your page content goes here */}
      </Box>
    </Box>
  );
};

export default Navbar;
