// aboutPageData.js
export const expertise = [
    { 
      title: 'מתמטיקה', 
      icon: 'CalculateOutlined', 
      level: '5 יחידות', 
      details: 'התמחות באנליזה, גיאומטריה ומתמטיקה תיאורטית, עם דגש על פיתוח הבנה מעמיקה ' 
    },
    { 
        title: 'פיזיקה', 
        icon: 'ScienceOutlined', 
        level: '5 יחידות', 
        details: 'שילוב מעמיק בין תיאוריה לבין יישום מעשי, עם דגש על הבנת עקרונות פיזיקליים ופתרון בעיות ברמת מורכבות גבוהה.' 
      },
      { 
        title: 'מדעי המחשב', 
        icon: 'ComputerOutlined', 
        level: '5 יחידות', 
        details: 'התמחות בפיתוח אלגוריתמים, תכנות מתקדם בשפות React, Node.js, C#, WPF, ASP.NET, ושילוב טכנולוגיות במסחר אלגוריתמי בשוק ההון.' 
      }
      
  ];
  
  export const achievements = [
    { 
      icon: 'Lightbulb', 
      title: 'תשוקה למתמטיקה', 
      text: ' בגיל 15 נוצר לי חלום להמציא משפט מתמטי חדש, מתוך אהבה עמוקה למקצוע'
    },
    { 
      icon: 'Psychology', 
      title: 'שיטת הוראה ייחודית', 
      text: 'פיתחתי גישה המשלבת הבנה תיאורטית מעמיקה עם תרגול אינטנסיבי, המותאמת אישית לכל תלמיד'
    },
    { 
      icon: 'TrendingUp', 
      title: 'הישגי תלמידים', 
      text: 'למעלה מ-100 תלמידים שהגיעו להישגים מרשימים בבגרויות ובלימודים האקדמיים'
    },
    {
        icon: 'AutoGraph', 
        title: 'פיתוח טכנולוגי', 
        text: 'אני עובד בשיתוף עם מפתחים על פלטפורמה למסחר בשוק ההון הכוללת אלגוריתם לניהול השקעות, ובמקביל מפתח את הפלטפורמה הלימודית הייחודית הזאת, שמטרתה לשפר את תהליכי הלמידה ולהפוך אותם לאפקטיביים ונגישים יותר.'
      }
      
  ];
  
  export const timeline = [
    {
      year: '2005',
      title: 'ההתחלה המוקדמת',
      content: 'בגיל 5 התחלתי לאהוב מספרים, ונהניתי לחשב מכפלות ולגלות את הקסם שבמתמטיקה.',
      icon: 'ChildFriendly'
    },
    {
      year: '2015',
      title: 'תחילת הדרך',
      content: 'במהלך שיעור מתמטיקה, החלטתי שאני רוצה להמציא משפטים חדשים ולהעמיק את הידע במתמטיקה.',
      icon: 'School'
    },
    {
      year: '2016-2018',
      title: 'התפתחות מקצועית',
      content: 'פיתוח שיטת הלימוד הייחודית שלי, שמשלבת הבנה תאורטית עם תרגול אינטנסיבי ומותאם אישית.',
      icon: 'Psychology'
    },
    {
      year: '2018-היום',
      title: 'הרחבת פעילות',
      content: 'לימדתי מעל 100 תלמידים, ועזרתי להם להשיג תוצאות מרשימות במבחנים ובגרויות .',
      icon: 'Star'
    },
    {
      year: '2024-היום',
      title: 'חדשנות טכנולוגית',
      content: 'אני מפתח כיום פלטפורמה טכנולוגית מתקדמת לשיפור תהליכי הלמידה, תוך שילוב של כלים מתמטיים ותכנותיים.',
      icon: 'Code'
    }
  ];

  
  export const teachingPhilosophy = [
    {
      icon: 'Architecture',
      title: 'בניית בסיס איתן',
      text: 'כל נושא מתחיל מהבנת היסודות העמוקים ובניית תשתית ידע חזקה'
    },
    {
      icon: 'Psychology',
      title: 'התאמה אישית',
      text: 'זיהוי הצרכים הייחודיים של כל תלמיד ובניית תכנית למידה מותאמת'
    },
    {
      icon: 'AutoGraph',
      title: 'תרגול מתקדם',
      text: 'שילוב בין תרגול אינטנסיבי להבנה תיאורטית עמוקה'
    }
  ];
  
  export const testimonials = [
    {
      name: "תומר לוי",
      grade: "בוגר 5 יחידות מתמטיקה",
      rating: 5,
      text: "אוהד לימד אותי לא רק לפתור תרגילים, אלא להבין את העומק של המתמטיקה. הגישה שלו שינתה לחלוטין את היחס שלי למקצוע."
    },
    {
      name: "מיכל כהן",
      grade: "סטודנטית לפיזיקה",
      rating: 5,
      text: "השיטה הייחודית של אוהד, המשלבת תיאוריה ותרגול, עזרה לי להתגבר על הפחד מפיזיקה ולהגיע להישגים מדהימים."
    },
    {
      name: "אלון גולן",
      grade: "תלמיד מדעי המחשב",
      rating: 5,
      text: "היכולת של אוהד לשלב בין מתמטיקה, פיזיקה ותכנות פתחה בפני עולם שלם של אפשרויות. היום אני מבין שהכל מתחבר."
    }
  ];
  
  export const personalStory = {
    title: "הסיפור שלי",
    paragraphs: [
      "בכיתה י׳, במהלך שיעור גאומטריה, כל כך התרגשתי מהפתרון ואמרתי: ״אני הולך להמציא משפט במתמטיקה״. עבורי, מתמטיקה היא לא רק מקצוע - היא מייצגת את העולם, מקור אינסופי של ידע והשראה.",
      "בכיתה ח׳ שיבצו אותי בהקבצה א׳2 כי טסנו לחו״ל ודילגתי על מבחן, ולא האמנתי שאותי ישימו בא׳2. כשרציתי לעלות אמרו לי: ׳תקבל ציונים טובים ונעלה אותך׳. גם כשחזרתי לארץ ידעתי את החומר ממש טוב, אבל הייתי צריך לעבוד קשה להשלים את הפער. כמובן שעליתי לא׳1 אחרי המבחן הראשון של המחצית. הצלחתי לעשות זאת באמצעות תרגול אינטנסיבי, ואז הבנתי - גם כשיש הבנה מיידית ולפעמים גם עמוקה, ללא תרגול, ההצלחה לא מובטחת. זו התובנה שהובילה אותי לפתח שיטת לימוד המשלבת הבנה תיאורטית עמוקה עם תרגול מדויק.",
      "היום, אחרי שנים של הוראת עשרות תלמידים, אני משלב את אהבתי למתמטיקה ומדעים עם פיתוח טכנולוגי. אני שותף בפיתוח אלגוריתמים למסחר במניות ומפתח פלטפורמה חדשנית זו, שתעזור לאינספור תלמידים להצליח."
    ]
  };