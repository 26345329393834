import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { List, Typography, Box, styled } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LayersIcon from '@mui/icons-material/Layers';
import dayjs from 'dayjs';

// Mobile-optimized styled components
const LessonItem = styled('li')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  listStyle: 'none',
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  }
}));

const LessonContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%'
});

const LessonHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
});

const LessonIconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 48,
  height: 48,
  flexShrink: 0
}));

const LessonNumber = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '0.875rem'
}));

const LessonTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1rem',
  marginBottom: '4px'
});

const LessonDescription = styled(Typography)({
  fontSize: '0.875rem',
  color: 'text.secondary',
  marginBottom: '12px'
});

const MetaInfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px'
});

const MetaInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '0.875rem',
  color: 'text.secondary',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
});

const ActionButton = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  textDecoration: 'none',
  fontSize: '0.875rem',
  fontWeight: 'medium',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

const getLessonIcon = (status, index) => {
  const icons = [
    <SchoolIcon fontSize="medium" color="primary" />,
    <LayersIcon fontSize="medium" color="primary" />,
    <StarIcon fontSize="medium" color="primary" />,
    <MenuBookIcon fontSize="medium" color="primary" />
  ];
  if (status === 'record') {
    return <PlayCircleOutlineIcon fontSize="medium" color="primary" />;
  }
  return icons[index % icons.length];
};

const LessonMetaInfo = memo(({ lesson }) => (
  <MetaInfoContainer>
    {lesson.status !== 'record' && (
      <MetaInfo>
        <CalendarTodayIcon />
        <span>
          {lesson.startDateTime
            ? dayjs(lesson.startDateTime).format('DD/MM/YYYY HH:mm')
            : 'תאריך לא נקבע'}
        </span>
      </MetaInfo>
    )}
    <MetaInfo>
      <AccessTimeIcon />
      <span>
        {lesson.duration ? `${lesson.duration} דקות` : 'לא ידוע'}
      </span>
    </MetaInfo>
  </MetaInfoContainer>
));

const LessonCard = memo(({ lesson, order, index }) => (
  <LessonItem>
    <LessonContent>
      <LessonHeader>
        <LessonIconContainer>
          {getLessonIcon(lesson.status, index)}
        </LessonIconContainer>
        <Box sx={{ flex: 1 }}>
          <LessonNumber>
            שיעור {order}
            <StarIcon fontSize="small" />
          </LessonNumber>
          <LessonTitle>
            {lesson.title}
          </LessonTitle>
        </Box>
      </LessonHeader>

      <LessonDescription>
        {lesson.description?.length > 100
          ? `${lesson.description.substring(0, 100)}...`
          : lesson.description || 'אין תיאור זמין.'}
      </LessonDescription>

      <LessonMetaInfo lesson={lesson} />

      <ActionButton to={`/lessons/${lesson._id}`}>
        לפרטים נוספים
        <ArrowForwardIcon fontSize="small" />
      </ActionButton>
    </LessonContent>
  </LessonItem>
));

const UnitLessons = ({ roadmap = [], lessons }) => {
  const sortedRoadmap = React.useMemo(() => 
    [...roadmap].sort((a, b) => a.order - b.order),
    [roadmap]
  );

  if (!roadmap.length) {
    return (
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{ textAlign: 'center', py: 4 }}
      >
        אין שיעורים זמינים ביחידה זו.
      </Typography>
    );
  }

  return (
    <List sx={{ 
      pt: 2, 
      px: { xs: 2, sm: 3 },
      maxWidth: '100%'
    }}>
      {sortedRoadmap.map((item, index) => {
        const lesson = lessons.find((l) => l._id === item.lesson._id) || item.lesson;
        return (
          <LessonCard
            key={lesson._id}
            lesson={lesson}
            order={item.order}
            index={index}
          />
        );
      })}
    </List>
  );
};

export default memo(UnitLessons);