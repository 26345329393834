// AboutPage.js
import React from 'react';
import { 
  Box, Typography, Avatar, Grid, Paper, Button, Container, Chip, IconButton,
  Card, CardContent, Rating, Divider
} from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineContent, TimelineDot, TimelineConnector } from '@mui/lab';
import * as Icons from '@mui/icons-material';
import { motion } from 'framer-motion';
import {
  expertise, achievements, timeline, teachingPhilosophy,
  testimonials, personalStory
} from './aboutPageData';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  const getIcon = (iconName) => {
    const Icon = Icons[iconName];
    return Icon ? <Icon /> : null;
  };

  return (
    <Container maxWidth="lg">
      <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} >
        {/* Header */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
          >
            <Avatar
              src="/images/ohad.jpg"
              sx={{
                width: { xs: 200, md: 250 },
                height: { xs: 200, md: 250 },
                mx: 'auto',
                mb: 3,
                border: 6,
                borderColor: 'primary.main'
              }}
            />
          </motion.div>
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <Typography variant="h2" gutterBottom>
              אוהד ניסים
            </Typography>
            <Typography variant="h5" color="text.secondary" gutterBottom>
              מורה פרטי | מומחה מתמטיקה ופיזיקה | מפתח תוכנה
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
              {['LinkedIn', 'GitHub', 'WhatsApp', 'Email'].map((platform) => (
                <IconButton key={platform} color="primary" size="large">
                  {getIcon(platform)}
                </IconButton>
              ))}
            </Box>
          </motion.div>
        </Box>

        {/* Personal Story */}
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            mb: 8, 
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -20,
              right: -20,
              fontSize: '200px',
              color: 'primary.main',
              opacity: 0.03,
              transform: 'rotate(-10deg)'
            }}
          >
            {getIcon('FormatQuote')}
          </Box>
          <Typography variant="h4" gutterBottom textAlign="center">
            {personalStory.title}
          </Typography>
          {personalStory.paragraphs.map((paragraph, index) => (
            <Typography key={index} paragraph={index < personalStory.paragraphs.length - 1}>
              {paragraph}
            </Typography>
          ))}
        </Paper>

        {/* Expertise Section */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            תחומי התמחות
          </Typography>
          <Grid container spacing={4} sx={{ mt: 3 }}>
            {expertise.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                >
                  <Paper 
                    sx={{ 
                      p: 3, 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center',
                      textAlign: 'center',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                    elevation={3}
                  >
                    <Box 
                      sx={{ 
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '100%',
                        height: '4px',
                        background: 'primary.main',
                        opacity: 0.7
                      }} 
                    />
                    <Box sx={{ color: 'primary.main', mb: 2, fontSize: '2.5rem' }}>
                      {getIcon(item.icon)}
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      {item.title}
                    </Typography>
                    <Chip label={item.level} color="primary" sx={{ mb: 2 }} />
                    <Typography color="text.secondary">
                      {item.details}
                    </Typography>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Teaching Philosophy */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            פילוסופיית ההוראה
          </Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {teachingPhilosophy.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Paper 
                    elevation={3} 
                    sx={{ 
                      p: 4, 
                      height: '100%',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ color: 'primary.main', mb: 2, fontSize: '3rem' }}>
                      {getIcon(item.icon)}
                    </Box>
                    <Typography variant="h5" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.text}
                    </Typography>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
{/* Timeline */}
<Box sx={{ mb: 8 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            המסע המקצועי
          </Typography>
          <Timeline position="alternate">
            {timeline.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    {getIcon(item.icon)}
                  </TimelineDot>
                  {index < timeline.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <motion.div
                    initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                  >
                    <Paper elevation={3} sx={{ p: 3 }}>
                      <Typography variant="overline" color="primary">
                        {item.year}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.content}
                      </Typography>
                    </Paper>
                  </motion.div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>

        {/* Achievements Grid */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            הישגים ויכולות
          </Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {achievements.map((achievement, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                >
                  <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ color: 'primary.main', mr: 2, fontSize: '2rem' }}>
                        {getIcon(achievement.icon)}
                      </Box>
                      <Typography variant="h6">
                        {achievement.title}
                      </Typography>
                    </Box>
                    <Typography color="text.secondary">
                      {achievement.text}
                    </Typography>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Testimonials 
        <Box sx={{ mb: 8 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            מה התלמידים מספרים
          </Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                >
                  <Card 
                    elevation={3}
                    sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative'
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Box sx={{ mb: 2 }}>
                        {getIcon('FormatQuote')}
                      </Box>
                      <Typography paragraph>
                        {testimonial.text}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        {testimonial.name}
                      </Typography>
                      <Typography color="text.secondary" gutterBottom>
                        {testimonial.grade}
                      </Typography>
                      <Rating value={testimonial.rating} readOnly />
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
        */}
        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <Paper 
            elevation={4}
            sx={{ 
              textAlign: 'center',
              p: 6,
              bgcolor: 'primary.main',
              color: 'white',
              borderRadius: 4
            }}
          >
            <Typography variant="h4" gutterBottom>
              מוכנים להתחיל במסע הלמידה?
            </Typography>
            <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
              יחד נבנה את הדרך להצלחה שלך
            </Typography>
            <Button 
              variant="contained" 
              color="secondary"
              component={Link}
              to={'/contact'}
              size="large"
              sx={{ 
                px: 4, 
                py: 1.5,
                fontSize: '1.2rem'
              }}
            >
              צור קשר עכשיו
            </Button>
          </Paper>
        </motion.div>
      </Box>
    </Container>
  );
};

export default AboutUs;