import React from 'react';
import { Box, Typography } from '@mui/material';
import { VideoContainer, ContentSection } from './styles';

const LessonVideo = ({ status, videoUrl, title }) => {
  if (!videoUrl) return null;

  return (
    <ContentSection>
      <Typography variant="h5" gutterBottom fontWeight="bold">
        {status === 'live' ? 'השיעור החי' : 'הקלטת השיעור'}
      </Typography>
      <VideoContainer>
        <iframe
          src={videoUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title={title || 'Lesson Video'}
        />
      </VideoContainer>
    </ContentSection>
  );
};

export default React.memo(LessonVideo);
