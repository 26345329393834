// src/components/Checkout/PlanDetails/PlanMainDetails.js
import React from 'react';
import { 
  Box, Typography, Chip, Zoom,
  Paper, Stack, Grid, Card,
  CardContent
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DescriptionIcon from '@mui/icons-material/Description';
import TitleIcon from '@mui/icons-material/Title';
import PaymentsIcon from '@mui/icons-material/Payments';

export const PlanMainDetails = ({ plan }) => {
  const discountAvailable = plan.disPrice && plan.disPrice < plan.price;
  const discountPercentage = discountAvailable
    ? Math.round(((plan.price - plan.disPrice) / plan.price) * 100)
    : null;

  return (
    <Stack spacing={2}>
      {/* Title Section */}
      <Zoom in timeout={1200}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    borderRadius: '50%',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <TitleIcon sx={{ color: 'white' }} />
                </Box>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" gutterBottom>
                  {plan.title}
                </Typography>
                <Chip 
                  label="תוכנית לימודים"
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Zoom>

      {/* Description Section */}
      <Zoom in timeout={1400}>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
              transform: 'translateY(-2px)'
            }
          }}
        >
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Box
              sx={{
                bgcolor: 'secondary.main',
                borderRadius: '50%',
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <DescriptionIcon sx={{ color: 'white' }} />
            </Box>
            <Box>
              <Typography 
                variant="subtitle2" 
                color="text.secondary" 
                gutterBottom
              >
                תיאור התוכנית
              </Typography>
              <Typography>
                {plan.description || 'אין תיאור זמין'}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Zoom>

      {/* Price Section */}
      <Zoom in timeout={1600}>
        <Card
          variant="outlined"
          sx={{
            position: 'relative',
            overflow: 'visible',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 2
            }
          }}
        >
          {discountAvailable && (
            <Box
              sx={{
                position: 'absolute',
                top: -12,
                right: 16,
                transform: 'rotate(15deg)'
              }}
            >
              <Chip
                icon={<LocalOfferIcon />}
                label={`${discountPercentage}% הנחה`}
                color="error"
                sx={{
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': { transform: 'scale(1)' },
                    '50%': { transform: 'scale(1.05)' },
                    '100%': { transform: 'scale(1)' }
                  }
                }}
              />
            </Box>
          )}
          <CardContent>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PaymentsIcon color="primary" />
                <Typography variant="subtitle1" color="text.secondary">
                  מחיר התוכנית
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
                {discountAvailable && (
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{ 
                      textDecoration: 'line-through',
                      opacity: 0.7
                    }}
                  >
                    ₪{plan.price.toLocaleString()}
                  </Typography>
                )}
                <Typography 
                  variant="h4" 
                  color="primary"
                  sx={{ 
                    fontWeight: 'bold',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                  }}
                >
                  ₪{(discountAvailable ? plan.disPrice : plan.price).toLocaleString()}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Zoom>
    </Stack>
  );
};