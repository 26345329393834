import React from 'react';
import { Box, CircularProgress, Typography, Container } from '@mui/material';

const LoadingState = ({ message = 'כמה רגעים...' }) => (
  <Container>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px',
        gap: 2
      }}
    >
      <CircularProgress size={60} />
      <Typography variant="h6" color="text.secondary">
        {message}
      </Typography>
    </Box>
  </Container>
);

export default LoadingState;
