import React from 'react';
import { styled } from '@mui/material/styles';
import { ContentSection } from './styles'; 
import { Box, Typography } from '@mui/material';
import { PdfViewerWrapper } from '../../Widgets/PdfViewerWrapper';

const StyledContentSection = styled(ContentSection)(({ theme }) => ({
  marginTop: theme.spacing(4),
  '& .pdf-title': {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

const LessonPdfSection = ({ pdfLinks , lessonId }) => {
  if (!pdfLinks || pdfLinks.length === 0) return null;

  return (
    <StyledContentSection>
      {pdfLinks.map((pdfItem, index) => (
        <Box key={index} mb={4}>
          <PdfViewerWrapper url={`${process.env.REACT_APP_INFINITY_BACK_URL}/api/uploads/lessons/${lessonId}/${pdfItem.fileName}`} title={pdfItem.title} />
        </Box>
      ))}
    </StyledContentSection>
  );
};

export default LessonPdfSection;
