import React, { useState } from 'react';
import PdfViewer from './PdfViewer';
import { PictureAsPdf } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { Box , Button } from '@mui/material';

export const PdfButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius * 3,
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

// Wrapper component that combines the button and viewer
export const PdfViewerWrapper = ({ url, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PdfButton
        variant="outlined"
        color="primary"
        onClick={() => setIsOpen(true)}
        startIcon={<PictureAsPdf />}
      >
        {title}
      </PdfButton>

      {isOpen && (
        <Box sx={{ mt: 2 }}>
          <PdfViewer url={url} title={title} />
        </Box>
      )}
    </>
  );
};