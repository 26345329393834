import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, Avatar, Chip, Button } from '@mui/material';

export const LessonHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.95)}, ${alpha(theme.palette.primary.dark, 0.95)})`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(8, 4),
  borderRadius: theme.shape.borderRadius * 2,
  position: 'relative',
  backdropFilter: 'blur(10px)',
  boxShadow: `0 8px 32px ${alpha(theme.palette.primary.main, 0.2)}`,
}));

export const LessonIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  color: theme.palette.primary.main,
  position: 'absolute',
  top: theme.spacing(-6),
  left: '50%',
  transform: 'translateX(-50%)',
  boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.2)}`,
  border: `4px solid ${theme.palette.primary.main}`,
}));

export const LessonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '2.5rem',
  marginTop: theme.spacing(2),
  textShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.2)}`,
}));

export const CourseTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  marginTop: theme.spacing(1),
  opacity: 0.9,
}));

export const StatusChip = styled(Chip)(({ theme, status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'live':
        return {
          bg: theme.palette.success.main,
          light: alpha(theme.palette.success.main, 0.1),
        };
      case 'scheduled':
        return {
          bg: theme.palette.info.main,
          light: alpha(theme.palette.info.main, 0.1),
        };
      case 'passed':
        return {
          bg: theme.palette.grey[500],
          light: alpha(theme.palette.grey[500], 0.1),
        };
      case 'canceled':
        return {
          bg: theme.palette.error.main,
          light: alpha(theme.palette.error.main, 0.1),
        };
      default:
        return {
          bg: theme.palette.warning.main,
          light: alpha(theme.palette.warning.main, 0.1),
        };
    }
  };

  const colors = getStatusColor(status);

  return {
    marginTop: theme.spacing(2),
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 600,
    '& .MuiChip-label': {
      padding: theme.spacing(1, 2),
    },
  };
});

export const ContentSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 8px 24px ${alpha(theme.palette.common.black, 0.1)}`,
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.shape.borderRadius * 3,
  fontWeight: 600,
  boxShadow: `0 4px 14px ${alpha(theme.palette.primary.main, 0.25)}`,
  transition: theme.transitions.create(['transform', 'box-shadow']),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.35)}`,
  },
}));

export const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.15)}`,
  '&::before': {
    content: '""',
    display: 'block',
    paddingTop: '56.25%', // 16:9 Aspect Ratio
  },
  '& > *': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));
