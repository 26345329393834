// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import AboutUs from './components/npc/AboutUs/AboutUs';
import ContactUs from './components/npc/ContactUs';
import NotFound from './components/npc/NotFound';
import CssBaseline from '@mui/material/CssBaseline';
import { UserProvider } from './context/UserContext';
import { ClerkProvider } from '@clerk/clerk-react'
import PrivacyPolicy from './components/npc/PrivacyPolicy';
import { DataProvider } from './context/DataContext';
import CourseDetails from './components/Course/CourseDetails/CourseDetails';
import MyCourses from './components/Course/MyCourses/MyCourses';
import AllCourses from './components/Course/AllCourses/AllCourses';
import AllPlans from './components/Plan/AllPlans';
import MySubscription from './components/Subscription/MySubscription1';
import MyLessons from './components/Lesson/MyLessons/MyLessons';
import StudyMaterialList from './components/StudyMaterials/StudyMaterialsList';
import StudyMaterial from './components/StudyMaterials/StudyMaterial';

import createEmotionCache from './theme/createEmotionCache';
import { CacheProvider } from '@emotion/react';
import ThemeContextProvider from './context/ThemeContext';
import MyStudyMaterialList from './components/StudyMaterials/MyStudyMaterialList';
import Checkout from './components/Checkout/Checkout';
import Profile from './components/Profile/Profile1';
import LessonDetails from './components/Lesson/LessonDetails/LessonsDetails1';
import AppLoader from './components/AppLoader/AppLoader';

const PUBLISHABLE_KEY = "pk_test_ZXZvbHZpbmctcHJhd24tNDguY2xlcmsuYWNjb3VudHMuZGV2JA";
const clerkFrontendApi = process.env.REACT_APP_CLERK_FRONTEND_API;



if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}


const cacheRtl = createEmotionCache();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>

      <Route index element={<Home />} />

      <Route path="contact" element={<ContactUs />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />


      <Route path="all-courses" element={<AllCourses />} />
      {/* <Route path="all-courses" element={<AllCoursesWithFilters />} /> */}
      <Route path="all-plans" element={<AllPlans />} />
      <Route path="my-courses" element={<MyCourses />} />
      <Route path="my-lessons" element={<MyLessons />} />
      <Route path="my-study-materials" element={<MyStudyMaterialList />} />
      

      <Route path="study-materials/" element={<StudyMaterialList />} />
      <Route path="study-materials/:lessonId" element={<StudyMaterialList />} />
      <Route path="study-materials/:courseId" element={<StudyMaterialList />} />

      <Route path="study-material/:id/:courseId" element={<StudyMaterial />} />

      <Route path="course/:courseId/lessons/:lessonId" element={<LessonDetails />} />
      <Route path="lessons/:lessonId" element={<LessonDetails />} />


      <Route path="/my-subscription" element={<MySubscription />} />
            
      <Route path="/course/:courseId/details" element={<CourseDetails />} />

      <Route path="/checkout/:planId" element={<Checkout />} />

      <Route path="/profile" element={<Profile />} />

      

      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ClerkProvider frontendApi={clerkFrontendApi} publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <UserProvider>
        <DataProvider>
        <CacheProvider value={cacheRtl}>
            <ThemeContextProvider>
              <CssBaseline />
                {/* <AppLoader> */}
                  <RouterProvider router={router} />
                {/* </AppLoader> */}
            </ThemeContextProvider>
          </CacheProvider>
        </DataProvider>
      </UserProvider>
    </ClerkProvider>
  </React.StrictMode>
);

reportWebVitals();
