
import React from 'react';
import { Box, Fade } from '@mui/material';
import { 
  LessonHeader, 
  LessonIcon, 
  LessonTitle, 
  CourseTitle, 
  StatusChip 
} from './styles';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

const LessonHeaderComponent = ({ lesson, course }) => {
  return (
    <Fade in timeout={800}>
      <LessonHeader>
          <LessonIcon>
            <AllInclusiveIcon/>
          </LessonIcon>

        <LessonTitle variant="h4" component="h1">
          {lesson.title}
        </LessonTitle>
        {course && (
          <CourseTitle variant="subtitle1">
            <strong>קורס:</strong> {course.title}
          </CourseTitle>
        )}

        
        <StatusChip 
          label={lesson.status === 'live' ? 'שידור חי' :
                 lesson.status === 'scheduled' ? 'מתוכנן' :
                 lesson.status === 'record'? 'מוקלט' :
                 lesson.status === 'passed' ? 'הסתיים' :
                 lesson.status === 'canceled' ? 'בוטל' : 'לא ידוע'}
          status={lesson.status}
        />
      </LessonHeader>
    </Fade>
  );
};

export default React.memo(LessonHeaderComponent);
