import React, { useState, useEffect } from 'react';
import './AppLoader.css';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

function AppLoader({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    // Simulate or do real checks
    setTimeout(() => {
      setIsLoading(false);
      // Wait for CSS fade-out, then remove from DOM
      setTimeout(() => setShowLoading(false), 500);
    }, 2000);
  }, []);

  return (
    <>
      {showLoading && (
        <div className={`loader-container ${!isLoading ? 'fade-out' : ''}`}>
          <LoadingScreen />
        </div>
      )}

      {/* The main app is behind the loading screen. */}
      {children}
    </>
  );
}

export default AppLoader;
