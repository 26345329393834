import React from 'react';
import { Box, Typography } from '@mui/material';
import QuestionCard from './QuestionCard';

const HomeworkSection = ({ questions = [] }) => {
  // אם אין שיעורי בית (questions ריק), לא מציגים שום דבר
  if (questions.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          fontWeight: 'bold',
          mb: 3,
          background: (theme) => 
            `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        שיעורי בית
      </Typography>

      {questions.map((question, index) => (
        <QuestionCard
          key={question._id}
          question={question}
          index={index}
        />
      ))}
    </Box>
  );
};

export default HomeworkSection;
