// PdfViewer.js
import React, { useState } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  ZoomIn,
  ZoomOut,
  Close,
  NavigateNext,
  NavigateBefore,
  Fullscreen,
  FullscreenExit,
  PictureAsPdf,
  SwapHoriz, // For toggling the view mode
} from '@mui/icons-material';

import { Page, Document, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const ViewerContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  height: '600px',
  display: 'flex',
  flexDirection: 'column',
}));

const ControlsBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
}));

const PageContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: alpha(theme.palette.common.black, 0.1),
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

const PdfViewer = ({ url, title }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [error, setError] = useState(null);

  // NEW: Control which mode to view: 'endless' (default) or 'single'
  const [viewMode, setViewMode] = useState('endless');

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
    setError(null);
  };

  const onDocumentLoadError = (err) => {
    console.error('Error loading PDF:', err);
    setError('Error loading PDF. Please try again.');
  };

  const handleZoomIn = () => setScale((prev) => Math.min(prev + 0.1, 2));
  const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.1, 0.5));
  const handleNextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages));
  const handlePrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));
  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);

  // NEW: Toggle between 'endless' and 'single' view modes
  const toggleViewMode = () => {
    setViewMode((prev) => (prev === 'endless' ? 'single' : 'endless'));
    // Reset to page 1 if switching to single-page mode
    if (viewMode === 'endless') {
      setPageNumber(1);
    }
  };

  const ViewerComponent = ({ children }) => {
    if (isFullscreen) {
      return (
        <Dialog fullScreen open={true} onClose={toggleFullscreen}>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {title}
              <IconButton onClick={toggleFullscreen}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
        </Dialog>
      );
    }
    return <ViewerContainer>{children}</ViewerContainer>;
  };

  const content = (
    <>
      <ControlsBar>
        {/* Left side controls: Zoom in/out */}
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton onClick={handleZoomOut}>
            <ZoomOut />
          </IconButton>
          <Typography variant="body2" sx={{ minWidth: 40 }}>
            {Math.round(scale * 100)}%
          </Typography>
          <IconButton onClick={handleZoomIn}>
            <ZoomIn />
          </IconButton>
        </Box>

        {/* Middle controls: Page navigation (only for single-page view) */}
        {viewMode === 'single' && (
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton onClick={handlePrevPage} disabled={pageNumber <= 1}>
              <NavigateBefore />
            </IconButton>
            <Typography>
              {pageNumber} / {numPages || '-'}
            </Typography>
            <IconButton onClick={handleNextPage} disabled={pageNumber >= numPages}>
              <NavigateNext />
            </IconButton>
          </Box>
        )}

        {/* Right side controls: Toggle view mode & fullscreen */}
        <Box display="flex" alignItems="center">
          <IconButton onClick={toggleViewMode}>
            <SwapHoriz />
          </IconButton>
          <IconButton onClick={toggleFullscreen}>
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
        </Box>
      </ControlsBar>

      <PageContainer>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={
            <Box display="flex" justifyContent="center" p={4}>
              <Typography>Loading PDF...</Typography>
            </Box>
          }
          error={
            <Box display="flex" justifyContent="center" p={4}>
              <Typography color="error">{error}</Typography>
            </Box>
          }
        >
          {viewMode === 'endless' ? (
            // ENDLESS MODE: Render all pages
            Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
                loading={
                  <Box display="flex" justifyContent="center" p={4}>
                    <Typography>Loading page...</Typography>
                  </Box>
                }
              />
            ))
          ) : (
            // SINGLE PAGE MODE: Render only one page at a time
            <Page
              pageNumber={pageNumber}
              scale={scale}
              loading={
                <Box display="flex" justifyContent="center" p={4}>
                  <Typography>Loading page...</Typography>
                </Box>
              }
            />
          )}
        </Document>
      </PageContainer>
    </>
  );

  return <ViewerComponent>{content}</ViewerComponent>;
};


export default PdfViewer;
